import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container } from "../components/ui"
import "./form.css"
import Layout from "../components/layout"
import {
  mobileNavOverlay,
  mobileNavLink,
  desktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
  mobileNavSVGColorWrapper,
} from "../components/header.css"


export default function IndexPage( { data }) {
  const { navItems, cta } = data
  const [isOpen, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [isOpen])



  return (
  <Layout>
    <Container className={desktopHeaderNavWrapper} width="narrow">
      <h1>Latest Updates</h1>
    <ul style={{ listStyle: "none" }}>
      {data.allWpPost.edges.map(post => (
        <li style={{ padding: "20px 0", borderBottom: "1px solid #ccc" }}>
          <Link
            to={`/${post.node.slug}`}
            style={{ display: "flex", color: "black", textDecoration: "none" }}
          >
            <GatsbyImage
                className="art-directed"
                alt="{post.featuredImage}"
                image={getImage(post.node.featuredImage.node.gatsbyImage)}
                
              />
            <div style={{ width: "75%", padding: "0 20px 20px" }}>
              <h3
                dangerouslySetInnerHTML={{ __html: post.node.title }}
                style={{ marginBottom: 0 }}
              />
              <p style={{ margin: 0, color: "grey" }}>
                {post.node.date}
              </p>
              <div dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
            </div>
          </Link>
        </li>
      ))}
    </ul>
    </Container>
    <Container className={mobileHeaderNavWrapper[isOpen ? "open" : "closed"]} width="narrow">
      <h1 style={{ width: "100%", padding: "0 0 0 40px" }}>Latest Updates</h1>
    <ul style={{ listStyle: "none" }}>
      {data.allWpPost.edges.map(post => (
        <li style={{ padding: "20px 0", borderBottom: "1px solid #ccc" }}>
          <Link
            to={`/${post.node.slug}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            <GatsbyImage
                className="art-directed"
                alt="{post.featuredImage}"
                image={getImage(post.node.featuredImage.node.gatsbyImage)}
                
              />
            <div style={{ width: "100%", padding: "0 20px 0 0" }}>
              <h3
                dangerouslySetInnerHTML={{ __html: post.node.title }}
                style={{ marginBottom: 0 }}
              />
              <p style={{ margin: 0, color: "grey" }}>
                {post.node.date}
              </p>
              <div dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
            </div>
          </Link>
        </li>
      ))}
    </ul>
    </Container>
  </Layout>
)

      }

export const query = graphql`
  query {
    allWpPost {
      edges {
        node {
          title
          excerpt
          slug
          author {
            node {
              name
            }
          }
          date(formatString: "DD, MMMM, YYYY")
          featuredImage {
            node {
              gatsbyImage(width: 300, quality: 100, placeholder: BLURRED)
              alt
            }
          }
        }
      }
    }
  }
`

